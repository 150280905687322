import Database from '../../Services/Database'
import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"

import LocalStorage from '../../Services/LocalStorage'

import "./SingleProduct.css"


function SingleProduct() {

    const id = useParams().id
    const [product, setProduct] = useState();
    const [quantity, setQuantity] = useState(1)

    useEffect(() => {
        Database.getProduct(id).then(data => {
            setProduct(data.data)
        }).catch(err => {
            console.log("there has been an error:" + err)
        })
    }, [id])

    function addToCart() {
        let item = {
            id: id,
            quantity: quantity,
            price: product.price,
            product
        }

        LocalStorage.addToCart(window, item)

        alert("Added to cart!")

    }

    const addQuantity = () => {
        setQuantity(quantity + 1)
    }

    const minusQuantity = () => {
        if (quantity === 1) return;
        setQuantity(quantity - 1)
    }

    if (product != null) {
        return (
            <div className='singleContainer'>
                <img src={product.image} alt={product.title} width="312px" height="auto" />
                <div className='productInformation'>
                    <h1>{product.title}</h1>
                    <p>{product.body}</p>
                </div>
                <div className='productInformation'>
                    <p>Tags: {product.tags.join(", ")}</p>
                    <p>In Stock: {product.inventory}</p>
                    <p>Price: {product.price}</p>
                    <p>Vendor: {product.vendor}</p>
                    <p>Category: {product.category}</p>
                </div>
                <div className='productInformation'>
                    <div className="quantityOrder">
                        <button onClick={minusQuantity}>-</button>
                        <p>{quantity}</p>
                        <button onClick={addQuantity}>+</button>
                    </div>
                    <button onClick={addToCart}>
                        Add to Cart!
                    </button>
                </div>
            </div>
        )
    }


}


export default SingleProduct;