import { useState, useEffect } from 'react'

import LocalStorage from '../../Services/LocalStorage'
import Database from '../../Services/Database'

function Orders() {
    const [orders, setOrders] = useState([])

    useEffect(() => {
        getOrders()
    }, [])

    async function getOrders() {
        let session = await LocalStorage.getSession(window);

        Database.getUsersOrders(session.user_id).then(c => {
            setOrders(c.data)
        }).catch(err => {
            console.log("there has been an error:" + err)
        })
    }

    return (
        <>
            {orders.map(c => (
                <div key={c.id} className="finalisedContainer">
                    <h1>Your order has been confirmed!</h1>
                    <p>Thank you for your order: #{c.id} placed on {c.order_date}</p>
                    <p>The current status is {c.order_status}</p>
                    <p>Order Total: ${c.order_total}</p>
                </div>))}
        </>
    )

}

export default Orders