import { useState, useEffect } from "react"

import Products from "../../components/Products/"

import Database from '../../Services/Database'

import "./ProductSearch.css"

function ProductSearch() {
    const [categories, setCategories] = useState([])
    const [products, setProducts] = useState([])

    useEffect(() => {
        Database.getCategories().then(response => {
            setCategories(response.data)
        }).catch(err => {
            console.log("there has been an error:" + err)
        })
        getProducts()
    }, []);

    const getProducts = () => {

        Database.getAllProducts().then(response => {
            setProducts(response.data)
        }).catch(err => {
            console.log("there has been an error:" + err)
        })
    }

    const filterProducts = (category) => {
        Database.getProductByCategory(category).then(response => {
            setProducts(response.data)
        }).catch(err => {
            console.log("there has been an error:" + err)
        })
    }

    return (
        <>
            <div className="productFilter">
                <p>Filter By:</p>
                {categories.map(c => <button key={c.value} className="filter" onClick={() => filterProducts(c.value)}>{c.display}</button>)}
                <button className="filter" onClick={getProducts}>Reset</button>

            </div>
            <div className="categoryContainer">
                <ul className="list">
                    {products.map(c => <Products key={c.id} product={c} />)}
                </ul>
            </div>
        </>
    )

}

export default ProductSearch;