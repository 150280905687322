import axios from 'axios'

const BASE_URL = "/api"

const getAllProducts = async () => {
    return axios.get(BASE_URL + "/products/")
}

const getCategories = async () => {
    return axios.get(BASE_URL + "/categories/")
}

const getProduct = async (id) => {
    return axios.get(BASE_URL + "/products/id/" + id)
}


const getProductByCategory = async (category) => {
    return axios.get(BASE_URL + "/products/category/" + category)
}


const getMaxProducts = async (max) => {
    return axios.get(BASE_URL + "/products?max=" + max)

}

const getSession = async () => {
    return axios.get(BASE_URL + "/session")
}

const getUsersOrders = async (id) => {
    return axios.get(BASE_URL + "/orders/" + id)
}

const addOrder = async (payload) => {
    return axios.post(BASE_URL + "/orders", payload)
}


const exported = {
    getAllProducts,
    getCategories,
    getProduct,
    getSession,
    getUsersOrders,
    addOrder,
    getMaxProducts,
    getProductByCategory
}

export default exported