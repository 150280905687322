import { Link } from "react-router-dom";

import LocalStorage from '../../Services/LocalStorage'

import { useEffect, useState } from 'react'

import "./Cart.css"

function Cart() {
    const [cart, updateCart] = useState([])
    const [totalCost, setTotalCost] = useState(0)

    useEffect(() => {
        getCart()
    }, [])

    async function getCart() {
        let data = await LocalStorage.getCart(window)

        let total = 0;
        for (let i = 0; i < data.length; i++) {
            total += data[i].price * data[i].quantity
        }
        setTotalCost(total)
        updateCart(data)
    }


    const removeItem = (cartItemId) => {
        LocalStorage.removeFromCart(window, cartItemId)
        setTimeout(() => {
            getCart()
        }, 1000)


        alert("Removed from Cart!")
    }

    const updateItem = (product) => {
        LocalStorage.updateCart(window, product)
        setTimeout(() => {
            getCart()
        }, 1000)
        alert("Your Cart has been Updated!")
    }


    return (
        <>
            {
                cart != null && cart.length > 0 ? (
                    <>
                        <h1 className="checkoutTitle">Cart Items:</h1>
                        {cart.map(item => <CartItem key={item.cartItemId} update={updateItem} remove={removeItem} product={item} />)}
                        <div className="checkoutContainer">
                            <h1>Cart Total: ${totalCost}</h1>
                            <Link className="checkoutButton" to="/checkout">Checkout</Link>
                        </div>
                    </>

                )
                    :

                    (
                        <p className="emptyCart">Your cart is empty</p>
                    )
            }
        </>
    )

}

function CartItem(props) {
    const product = props.product.product
    const [quantity, setQuantity] = useState(props.product.quantity)

    const updateItem = () => {
        props.product.quantity = quantity;
        props.update(props.product)
    }


    const addQuantity = () => {
        setQuantity(quantity + 1)
    }

    const minusQuantity = () => {
        if (quantity === 1) return;
        setQuantity(quantity - 1)
    }

    return (
        <div className='singleContainer'>
            <img src={product.image} alt={product.title} width="312px" height="auto" />
            <div className='productInformation'>
                <h1>{product.title}</h1>
                <p>{product.body}</p>
            </div>
            <div className='productInformation'>
                <p>Tags: {product.tags.join(", ")}</p>
                <p>In Stock: {product.inventory}</p>
                <p>Price: {product.price}</p>
                <p>Vendor: {product.vendor}</p>
                <p>Category: {product.category}</p>
            </div>
            <div className='productInformation'>
                <div className="quantityOrder">
                    <button onClick={minusQuantity}>-</button>
                    <p>{quantity}</p>
                    <button onClick={addQuantity}>+</button>
                </div>
                <button onClick={() => updateItem()}>
                    Update Cart!
                </button>

                <button onClick={() => props.remove(props.product.cartItemId)}>
                    Remove Product
                </button>
            </div>
        </div>
    )
}

export default Cart;