import { Link } from "react-router-dom";

import "./Products.css"

function Products({ product }) {

    return (
        <li className="products">
            <Link to={"/products/" + product.id} className="productLink">
                <div className="productContainer">
                    <img className="productImage" src={product.image} alt={product.title} width="254px" height="170px"></img>
                    <div className="productInformation">
                        <p>{product.title}</p>
                        <p>${product.price}</p>
                    </div>
                </div>
            </Link>
        </li>
    )
}

export default Products