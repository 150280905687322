import { useState, useEffect } from "react"

import Products from "../../components/Products/"

import Database from '../../Services/Database'

import './HomePage.css';

function HomePage() {
    const [categories, setCategories] = useState([])
    const [products, setProducts] = useState([])

    useEffect(() => {
        Database.getCategories().then(response => {
            setCategories(response.data)
        }).catch(err => {
            console.log("there has been an error:" + err)
        })

        Database.getMaxProducts(4).then(response => {
            setProducts(response.data)
        }).catch(err => {
            console.log("there has been an error:" + err)
        })
    }, []);

    return (
        <>
            {categories.map(c => <ProductSections key={c.value} category={c.display} products={products.filter(c2 => c2.category === c.value)} />)}
        </>
    )

}


function ProductSections({ category, products }) {

    return (
        <fieldset className="categoryContainer">
            <legend className="categoryName">{category}</legend>
            <ul className="list">
                {products.map(c => <Products key={c.id} product={c} />)}
            </ul>
        </fieldset>
    )

}

export default HomePage;