import Database from '../../Services/Database'
import LocalStorage from '../../Services/LocalStorage'

import "./Checkout.css"


import { useState, useEffect } from 'react'

function Checkout() {
    const [order, newOrder] = useState(false)
    const [added, setAdded] = useState(false)

    useEffect(() => {
        if (!added) {
            getCart()
        }
    }, [added])

    async function getCart() {
        setAdded(true);
        let data = await LocalStorage.getCart(window)
        let total = 0;

        let orderItems = []
        for (let i = 0; i < data.length; i++) {
            total += data[i].price * data[i].quantity
            orderItems.push({
                "product_id": data[i].id,
                "quantity": data[i].quantity,
                "price": data[i].price
            })
        }

        let orderObject = {
            "user_id": 1,
            "order_date": new Date().toLocaleString(),
            "order_status": "pending",
            "order_total": total,
            "order_items": orderItems
        }

        Database.addOrder(orderObject).then(data => {
            LocalStorage.clearCart(window)
            newOrder(data.data)
        }).catch(err => {
            console.log("there has been an error:" + err)
        })
    }
    if (order !== false) {
        return (
            <div className="finalisedContainer">
                <h1>Your order has been confirmed!</h1>
                <p>Thank you for your order: #{order.id}</p>
                <p>The current status is {order.order_status}</p>
            </div>
        )
    } else {
        return (
            <p>Your order has already been filled.</p>
        )
    }
}

export default Checkout;