import './App.css';
import Navigation from './components/Navigation'
import HomePage from "./Pages/HomePage"
import SingleProduct from "./Pages/SingleProduct"
import ProductSearch from "./Pages/ProductSearch"
import Cart from './Pages/Cart'
import Checkout from './Pages/Checkout'
import Orders from './Pages/Orders'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react"

import LocalStorage from './Services/LocalStorage'
import Database from "./Services/Database"


function App() {

  useEffect(() => {
    sessionHandler()
  }, [])

  async function sessionHandler() {
    if (await LocalStorage.getSession(window) == null) {
      Database.getSession().then(data => {
        let session = data.data;
        LocalStorage.addSession(window, session)
      }).catch(err => {
            console.log("there has been an error:" + err)
        })
    } else {
      //do nothing
    }
  }

  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/products" element={<ProductSearch />} />
        <Route path="/products/:id" element={<SingleProduct />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/orders" element={<Orders />} />
      </Routes>
    </Router>

  );
}


export default App;
