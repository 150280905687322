
import { NavLink } from "react-router-dom";

import "./Navigation.css"

function Navigation() {
  return (
    <nav className="navContainer">
      <div className="buttonContainer">
        <NavLink className="navButton" to="/">
          Home
        </NavLink>
        <NavLink className="navButton" to="/products">
          Search
        </NavLink>
        <NavLink className="navButton" to="/cart">
          Cart
        </NavLink>
        <NavLink className="navButton" to="/orders">
          Orders
        </NavLink>
      </div>
    </nav>
  );
}

export default Navigation;
